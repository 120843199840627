import React from 'react';
import HeroSection from './components/HeroSection';
import QuizSection from './components/QuizSection';
import BearsSection from './components/BearsSection';
import CallToAction from './components/CallToAction';
import ContactSection from './components/ContactSection';
import './App.css';

function App() {
  return (
    <div className="App">
      <div>HELLO JELLO</div>
      {/* <HeroSection />
      <QuizSection />
      <BearsSection />
      <CallToAction />
      <ContactSection /> */}
    </div>
  );
}

export default App;
